// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/organization.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Asset, PaymentProcessor, WebAnalyticsPlatform } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.consumer.v1.WebAnalyticsTag
 */
export const WebAnalyticsTag = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.WebAnalyticsTag",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "platform", kind: "enum", T: proto3.getEnumType(WebAnalyticsPlatform) },
    { no: 3, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.Organization
 */
export const Organization = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.Organization",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "logo", kind: "message", T: Asset },
    { no: 4, name: "primary_color_hex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "terms_of_service_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "privacy_policy_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "tilled_merchant_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "website_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "outbound_email_sender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "ticket_limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "is_season_packages_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "is_memberships_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "is_merchandise_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "is_prevent_orphan_seats_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "fee_label_copy", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "fee_tooltip_copy", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "merchandise_page_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "payment_processor", kind: "enum", T: proto3.getEnumType(PaymentProcessor) },
    { no: 21, name: "finix_merchant_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "finix_merchant_identity_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "is_apple_pay_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "is_google_pay_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "should_display_all_in_pricing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "seat_tooltip_copy", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "web_analytics_tags", kind: "message", T: WebAnalyticsTag, repeated: true },
    { no: 28, name: "shows_page_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 29, name: "cf_turnstile_site_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 30, name: "default_country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "available_country_codes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetOrganizationRequest
 */
export const GetOrganizationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetOrganizationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetOrganizationResponse
 */
export const GetOrganizationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetOrganizationResponse",
  () => [
    { no: 1, name: "org", kind: "message", T: Organization },
  ],
);

