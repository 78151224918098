// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/ticket_sales_stat.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Money } from "../../../common/v1/common_pb.js";

/**
 * TicketSalesStat is used by both Show and Showtime.
 * Note that total is not necessarily equal to sold + remaining.
 * Sold indicates whether money has been collected for the ticket.
 * Remaining indicates whether the ticket is still available for sale.
 *
 * @generated from message cuebox.services.staff.v1.TicketSalesStat
 */
export const TicketSalesStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketSalesStat",
  () => [
    { no: 1, name: "tickets_total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "tickets_booked_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "tickets_remaining_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "net_sales_amount", kind: "message", T: Money },
    { no: 5, name: "tickets_gross_sold_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "tickets_paid_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "seat_holds_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

