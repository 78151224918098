// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/renewal.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Address, PhoneNumber, RenewalStatus } from "../../../common/v1/common_pb.js";
import { TicketType } from "./ticket_type_pb.js";
import { SeatHold, SeatHoldOptions } from "./seat_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.TicketTypeMapping
 */
export const TicketTypeMapping = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketTypeMapping",
  () => [
    { no: 1, name: "old_ticket_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "new_ticket_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.RenewalProcess
 */
export const RenewalProcess = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RenewalProcess",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "season_package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "old_season_package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ended_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateRenewalProcessRequest
 */
export const CreateRenewalProcessRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateRenewalProcessRequest",
  () => [
    { no: 1, name: "season_package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "old_season_package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ticket_type_mappings", kind: "message", T: TicketTypeMapping, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateRenewalProcessResponse
 */
export const CreateRenewalProcessResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateRenewalProcessResponse",
  () => [
    { no: 1, name: "renewal_process", kind: "message", T: RenewalProcess },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.Renewal
 */
export const Renewal = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Renewal",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "renewal_process_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "household_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "consumer_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "consumer_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "consumer_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "consumer_phone_number", kind: "message", T: PhoneNumber },
    { no: 9, name: "consumer_address", kind: "message", T: Address },
    { no: 10, name: "status", kind: "enum", T: proto3.getEnumType(RenewalStatus) },
    { no: 11, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "ticket_types", kind: "message", T: TicketType, repeated: true },
    { no: 13, name: "original_seat_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "seat_holds", kind: "message", T: SeatHold, repeated: true },
    { no: 15, name: "order_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSeasonPackageRenewalProcessRequest
 */
export const GetSeasonPackageRenewalProcessRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSeasonPackageRenewalProcessRequest",
  () => [
    { no: 1, name: "season_package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSeasonPackageRenewalProcessResponse
 */
export const GetSeasonPackageRenewalProcessResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSeasonPackageRenewalProcessResponse",
  () => [
    { no: 1, name: "renewal_process", kind: "message", T: RenewalProcess },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.EndRenewalProcessRequest
 */
export const EndRenewalProcessRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.EndRenewalProcessRequest",
  () => [
    { no: 1, name: "renewal_process_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.EndRenewalProcessResponse
 */
export const EndRenewalProcessResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.EndRenewalProcessResponse",
  () => [
    { no: 1, name: "renewal_process", kind: "message", T: RenewalProcess },
  ],
);

/**
 * This endpoint would be called when the user clicks "Restart Renewal Process"
 *
 * @generated from message cuebox.services.staff.v1.ArchiveRenewalProcessRequest
 */
export const ArchiveRenewalProcessRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveRenewalProcessRequest",
  () => [
    { no: 1, name: "renewal_process_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveRenewalProcessResponse
 */
export const ArchiveRenewalProcessResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveRenewalProcessResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListRenewalsRequest
 */
export const ListRenewalsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListRenewalsRequest",
  () => [
    { no: 1, name: "season_package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListRenewalsResponse
 */
export const ListRenewalsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListRenewalsResponse",
  () => [
    { no: 1, name: "renewals", kind: "message", T: Renewal, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveRenewalRequest
 */
export const SaveRenewalRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveRenewalRequest",
  () => [
    { no: 1, name: "renewal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "new_seat_holds", kind: "message", T: SeatHoldOptions, repeated: true },
    { no: 4, name: "removed_seat_hold_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveRenewalResponse
 */
export const SaveRenewalResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveRenewalResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ReleaseRenewalProcessRequest
 */
export const ReleaseRenewalProcessRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ReleaseRenewalProcessRequest",
  () => [
    { no: 1, name: "renewal_process_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ReleaseRenewalProcessResponse
 */
export const ReleaseRenewalProcessResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ReleaseRenewalProcessResponse",
  [],
);

