import { useEffect, useMemo } from 'react';
import useLocalStorage from '@hooks/useLocalStorage';
import usePrevious from '@hooks/usePrevious';
import useUrlQuery from '@hooks/useUrlQuery';
import type {
  ListOrganizationsRequest,
  ListOrganizationsResponse,
} from '@pb/cuebox/services/staff/v1/organization_pb';
import { SECONDS_IN_DAY } from '@utils/dates';
import { createStaffFetcher } from '@utils/protobuf';

const { useFetcher: useListOrganizations } = createStaffFetcher<
  ListOrganizationsRequest,
  ListOrganizationsResponse
>({
  method: (service) => service.methods.listOrganizations,
  fetcher: ({ client, params }) => client.listOrganizations(params),
});

const CURRENT_ORG_SELECTION_TTL = SECONDS_IN_DAY * 365;

const useOrganizations = (userId: string | undefined) => {
  const { orgId = '' } = useUrlQuery<{ orgId: string }>();
  const previousOrgId = usePrevious(orgId);

  const { data: currentOrgId, setItem: setCurrentOrgId } =
    useLocalStorage<string>({
      initialData: orgId,
      storageKey: 'currentOrgId',
      ttlSeconds: CURRENT_ORG_SELECTION_TTL,
      shouldRefresh: true,
    });

  useEffect(() => {
    if (orgId && orgId !== previousOrgId) {
      setCurrentOrgId(orgId);
    }
  }, [orgId, previousOrgId, setCurrentOrgId]);

  const { data, isLoading, error } = useListOrganizations({
    shouldFetch: !!userId,
    params: { cueboxUserId: userId },
    useDefaultErrorHandling: false,
    onSuccess: (res) => {
      if (
        !currentOrgId ||
        !res?.organizations.some((org) => org.id === currentOrgId)
      ) {
        const firstOrgId = res?.organizations[0]?.id;

        if (firstOrgId) {
          setCurrentOrgId(firstOrgId);
        }
      }
    },
  });

  const organizations = useMemo(() => data?.organizations || [], [data]);
  const currentOrg = useMemo(
    () => organizations.find((org) => org.id === orgId) || organizations[0],
    [organizations, orgId],
  );

  return {
    currentOrg,
    currentOrgId: orgId || currentOrgId,
    isLoading,
    organizations,
    isEmpty: !isLoading && !organizations.length,
    error,
  };
};

export default useOrganizations;
