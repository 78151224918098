// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/cart.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { DonationForm } from "./donation_form_pb.js";
import { CartStatus, DiscountSource, Money, SeatingModel, SubscriptionFrequency, TicketDeliveryMethod, Tribute } from "../../../common/v1/common_pb.js";
import { InPersonLocation, Show, Showtime } from "./show_pb.js";
import { SeasonPackage } from "./season_package_pb.js";
import { PriceListView } from "./price_list_view_pb.js";
import { Membership } from "./membership_pb.js";
import { Merchandise } from "./merchandise_pb.js";
import { CheckoutForm, CheckoutFormSubmission } from "./form_question_pb.js";
import { Product } from "./product_pb.js";

/**
 * @generated from message cuebox.services.consumer.v1.CartOptions
 */
export const CartOptions = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.CartOptions",
  () => [
    { no: 1, name: "donation_form", kind: "message", T: DonationForm },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SelectedCartOptions
 */
export const SelectedCartOptions = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SelectedCartOptions",
  () => [
    { no: 1, name: "should_cover_processing_fee_if_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.CartItem
 */
export const CartItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.CartItem",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 100, name: "donation", kind: "message", T: DonationItem, oneof: "item" },
    { no: 101, name: "ticket", kind: "message", T: TicketItem, oneof: "item" },
    { no: 102, name: "season_package", kind: "message", T: SeasonPackageItem, oneof: "item" },
    { no: 103, name: "membership", kind: "message", T: MembershipItem, oneof: "item" },
    { no: 104, name: "merchandise", kind: "message", T: MerchandiseItem, oneof: "item" },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.DonationItem
 */
export const DonationItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.DonationItem",
  () => [
    { no: 1, name: "donation_form_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fund_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "price", kind: "message", T: PriceInfo },
    { no: 5, name: "frequency", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency) },
    { no: 6, name: "tribute", kind: "message", T: Tribute },
    { no: 7, name: "is_anonymous", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "appeal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.TicketItem
 */
export const TicketItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.TicketItem",
  () => [
    { no: 1, name: "show", kind: "message", T: Show },
    { no: 2, name: "showtime", kind: "message", T: Showtime },
    { no: 3, name: "ticket_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ticket_type_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "price_level_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "price_level_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "price", kind: "message", T: PriceInfo },
    { no: 8, name: "seat", kind: "message", T: Seat },
    { no: 9, name: "ticket_delivery_method", kind: "enum", T: proto3.getEnumType(TicketDeliveryMethod) },
    { no: 10, name: "season_package_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Seat not required for now, since we have TicketItem.seatsio_seat_id
 *
 * @generated from message cuebox.services.consumer.v1.Seat
 */
export const Seat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.Seat",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "seatsio_bookable_object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "section_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "row_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "seat_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_accessible", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SeasonPackageItem
 */
export const SeasonPackageItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SeasonPackageItem",
  () => [
    { no: 2, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "price", kind: "message", T: PriceInfo },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SeasonPackageGroup
 */
export const SeasonPackageGroup = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SeasonPackageGroup",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "season_package", kind: "message", T: SeasonPackage },
    { no: 3, name: "selected_showtime_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "ticket_selection_steps", kind: "message", T: SeasonPackageTicketSelectionStep, repeated: true },
  ],
);

/**
 * Read-only information used to inform the ticket selection UI.
 *
 * @generated from message cuebox.services.consumer.v1.SeasonPackageTicketSelectionStep
 */
export const SeasonPackageTicketSelectionStep = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SeasonPackageTicketSelectionStep",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "showtimes", kind: "message", T: Showtime, repeated: true },
    { no: 3, name: "seating_model", kind: "enum", T: proto3.getEnumType(SeatingModel) },
    { no: 4, name: "location", kind: "message", T: InPersonLocation },
    { no: 5, name: "aggregate_price_list", kind: "message", T: PriceListView },
    { no: 6, name: "is_complete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.MembershipItem
 */
export const MembershipItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.MembershipItem",
  () => [
    { no: 1, name: "membership", kind: "message", T: Membership },
    { no: 2, name: "price", kind: "message", T: PriceInfo },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.MerchandiseItem
 */
export const MerchandiseItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.MerchandiseItem",
  () => [
    { no: 1, name: "merchandise_sku_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "merchandise", kind: "message", T: Merchandise },
    { no: 3, name: "price", kind: "message", T: PriceInfo },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.PriceInfo
 */
export const PriceInfo = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.PriceInfo",
  () => [
    { no: 1, name: "base", kind: "message", T: Money },
    { no: 2, name: "suggested_base_modifier", kind: "message", T: Money },
    { no: 3, name: "accepted_base_modifier", kind: "message", T: Money },
    { no: 4, name: "subtotal", kind: "message", T: Money },
    { no: 5, name: "fee", kind: "message", T: Money },
    { no: 6, name: "discount", kind: "message", T: Money },
    { no: 7, name: "total", kind: "message", T: Money },
    { no: 8, name: "discounts", kind: "message", T: Discount, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.Discount
 */
export const Discount = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.Discount",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "amount", kind: "message", T: Money },
    { no: 4, name: "source", kind: "enum", T: proto3.getEnumType(DiscountSource) },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.Cart
 */
export const Cart = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.Cart",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(CartStatus) },
    { no: 6, name: "items", kind: "message", T: CartItem, repeated: true },
    { no: 7, name: "season_package_groups", kind: "message", T: SeasonPackageGroup, repeated: true },
    { no: 8, name: "price_summary", kind: "message", T: PriceInfo },
    { no: 9, name: "selected_options", kind: "message", T: SelectedCartOptions },
    { no: 10, name: "checkout_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "warning_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "form", kind: "message", T: CheckoutForm },
    { no: 13, name: "form_submission", kind: "message", T: CheckoutFormSubmission },
    { no: 14, name: "is_form_submission_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.CreateCartRequest
 */
export const CreateCartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.CreateCartRequest",
  [],
);

/**
 * @generated from message cuebox.services.consumer.v1.CreateCartResponse
 */
export const CreateCartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.CreateCartResponse",
  () => [
    { no: 1, name: "cart", kind: "message", T: Cart },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ResetCartRequest
 */
export const ResetCartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ResetCartRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ResetCartResponse
 */
export const ResetCartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ResetCartResponse",
  [],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetCartRequest
 */
export const GetCartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetCartRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetCartResponse
 */
export const GetCartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetCartResponse",
  () => [
    { no: 1, name: "cart", kind: "message", T: Cart },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SaveItemsRequest
 */
export const SaveItemsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SaveItemsRequest",
  () => [
    { no: 1, name: "cart_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "items", kind: "message", T: CartItem, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SaveItemsResponse
 */
export const SaveItemsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SaveItemsResponse",
  [],
);

/**
 * @generated from message cuebox.services.consumer.v1.RemoveDiscountRequest
 */
export const RemoveDiscountRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.RemoveDiscountRequest",
  () => [
    { no: 1, name: "cart_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "discount_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.RemoveDiscountResponse
 */
export const RemoveDiscountResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.RemoveDiscountResponse",
  [],
);

/**
 * @generated from message cuebox.services.consumer.v1.AddSeasonPackageGroupRequest
 */
export const AddSeasonPackageGroupRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.AddSeasonPackageGroupRequest",
  () => [
    { no: 1, name: "cart_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "season_package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.AddSeasonPackageGroupResponse
 */
export const AddSeasonPackageGroupResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.AddSeasonPackageGroupResponse",
  () => [
    { no: 1, name: "cart", kind: "message", T: Cart },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SaveSeasonPackageGroupShowtimesRequest
 */
export const SaveSeasonPackageGroupShowtimesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SaveSeasonPackageGroupShowtimesRequest",
  () => [
    { no: 1, name: "cart_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "season_package_group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "showtime_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SaveSeasonPackageGroupShowtimesResponse
 */
export const SaveSeasonPackageGroupShowtimesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SaveSeasonPackageGroupShowtimesResponse",
  () => [
    { no: 1, name: "cart", kind: "message", T: Cart },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetCartOptionsRequest
 */
export const GetCartOptionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetCartOptionsRequest",
  () => [
    { no: 1, name: "cart_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetCartOptionsResponse
 */
export const GetCartOptionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetCartOptionsResponse",
  () => [
    { no: 1, name: "options", kind: "message", T: CartOptions },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SaveSelectedCartOptionsRequest
 */
export const SaveSelectedCartOptionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SaveSelectedCartOptionsRequest",
  () => [
    { no: 1, name: "cart_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "selected_options", kind: "message", T: SelectedCartOptions },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SaveSelectedCartOptionsResponse
 */
export const SaveSelectedCartOptionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SaveSelectedCartOptionsResponse",
  [],
);

/**
 * @generated from message cuebox.services.consumer.v1.AddDiscountRequest
 */
export const AddDiscountRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.AddDiscountRequest",
  () => [
    { no: 1, name: "cart_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "discount_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "source", kind: "enum", T: proto3.getEnumType(DiscountSource) },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.AddDiscountResponse
 */
export const AddDiscountResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.AddDiscountResponse",
  () => [
    { no: 1, name: "cart", kind: "message", T: Cart },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SaveCheckoutEmailRequest
 */
export const SaveCheckoutEmailRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SaveCheckoutEmailRequest",
  () => [
    { no: 1, name: "cart_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SaveCheckoutEmailResponse
 */
export const SaveCheckoutEmailResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SaveCheckoutEmailResponse",
  () => [
    { no: 1, name: "cart", kind: "message", T: Cart },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SeatHold
 */
export const SeatHold = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SeatHold",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "consumer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "showtime_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "seatsio_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "seat_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "seatsio_bookable_object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListConsumerSeatHoldsRequest
 */
export const ListConsumerSeatHoldsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListConsumerSeatHoldsRequest",
  () => [
    { no: 1, name: "showtime_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListConsumerSeatHoldsResponse
 */
export const ListConsumerSeatHoldsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListConsumerSeatHoldsResponse",
  () => [
    { no: 1, name: "holds", kind: "message", T: SeatHold, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListAddOnProductsForCartRequest
 */
export const ListAddOnProductsForCartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListAddOnProductsForCartRequest",
  () => [
    { no: 1, name: "cart_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListAddOnProductsForCartResponse
 */
export const ListAddOnProductsForCartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListAddOnProductsForCartResponse",
  () => [
    { no: 1, name: "products", kind: "message", T: Product, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SaveCartFormSubmissionRequest
 */
export const SaveCartFormSubmissionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SaveCartFormSubmissionRequest",
  () => [
    { no: 1, name: "cart_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "form_submission", kind: "message", T: CheckoutFormSubmission },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SaveCartFormSubmissionResponse
 */
export const SaveCartFormSubmissionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SaveCartFormSubmissionResponse",
  [],
);

