// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/common/v1/common.proto (package cuebox.common.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum cuebox.common.v1.ConstituentType
 */
export const ConstituentType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.ConstituentType",
  [
    {no: 0, name: "CONSTITUENT_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CONSTITUENT_TYPE_INDIVIDUAL", localName: "INDIVIDUAL"},
    {no: 2, name: "CONSTITUENT_TYPE_HOUSEHOLD", localName: "HOUSEHOLD"},
    {no: 3, name: "CONSTITUENT_TYPE_COMPANY", localName: "COMPANY"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.TributeType
 */
export const TributeType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.TributeType",
  [
    {no: 0, name: "TRIBUTE_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TRIBUTE_TYPE_IN_HONOR_OF", localName: "IN_HONOR_OF"},
    {no: 2, name: "TRIBUTE_TYPE_IN_MEMORY_OF", localName: "IN_MEMORY_OF"},
    {no: 3, name: "TRIBUTE_TYPE_ACKNOWLEDGE_AS", localName: "ACKNOWLEDGE_AS"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.OrderItemType
 */
export const OrderItemType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.OrderItemType",
  [
    {no: 0, name: "ORDER_ITEM_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ORDER_ITEM_TYPE_DONATION", localName: "DONATION"},
    {no: 2, name: "ORDER_ITEM_TYPE_TICKET", localName: "TICKET"},
    {no: 3, name: "ORDER_ITEM_TYPE_SEASON_PACKAGE", localName: "SEASON_PACKAGE"},
    {no: 4, name: "ORDER_ITEM_TYPE_MEMBERSHIP", localName: "MEMBERSHIP"},
    {no: 5, name: "ORDER_ITEM_TYPE_MERCHANDISE", localName: "MERCHANDISE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.SubscriptionFrequency
 */
export const SubscriptionFrequency = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.SubscriptionFrequency",
  [
    {no: 0, name: "SUBSCRIPTION_FREQUENCY_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SUBSCRIPTION_FREQUENCY_ONE_TIME", localName: "ONE_TIME"},
    {no: 2, name: "SUBSCRIPTION_FREQUENCY_MONTHLY", localName: "MONTHLY"},
    {no: 3, name: "SUBSCRIPTION_FREQUENCY_QUARTERLY", localName: "QUARTERLY"},
    {no: 4, name: "SUBSCRIPTION_FREQUENCY_YEARLY", localName: "YEARLY"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.AcknowledgmentStatus
 */
export const AcknowledgmentStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.AcknowledgmentStatus",
  [
    {no: 0, name: "ACKNOWLEDGMENT_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ACKNOWLEDGMENT_STATUS_YES", localName: "YES"},
    {no: 2, name: "ACKNOWLEDGMENT_STATUS_NO", localName: "NO"},
    {no: 3, name: "ACKNOWLEDGMENT_STATUS_DO_NOT_ACKNOWLEDGE", localName: "DO_NOT_ACKNOWLEDGE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.EmailMarketingSubscriptionStatus
 */
export const EmailMarketingSubscriptionStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.EmailMarketingSubscriptionStatus",
  [
    {no: 0, name: "EMAIL_MARKETING_SUBSCRIPTION_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "EMAIL_MARKETING_SUBSCRIPTION_STATUS_NOT_SUBSCRIBED", localName: "NOT_SUBSCRIBED"},
    {no: 2, name: "EMAIL_MARKETING_SUBSCRIPTION_STATUS_SUBSCRIBED", localName: "SUBSCRIBED"},
    {no: 3, name: "EMAIL_MARKETING_SUBSCRIPTION_STATUS_UNSUBSCRIBED", localName: "UNSUBSCRIBED"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.OrderPaymentStatus
 */
export const OrderPaymentStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.OrderPaymentStatus",
  [
    {no: 0, name: "ORDER_PAYMENT_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ORDER_PAYMENT_STATUS_PAID", localName: "PAID"},
    {no: 2, name: "ORDER_PAYMENT_STATUS_REFUNDED", localName: "REFUNDED"},
    {no: 3, name: "ORDER_PAYMENT_STATUS_PARTIALLY_REFUNDED", localName: "PARTIALLY_REFUNDED"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.OrderItemPaymentStatus
 */
export const OrderItemPaymentStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.OrderItemPaymentStatus",
  [
    {no: 0, name: "ORDER_ITEM_PAYMENT_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ORDER_ITEM_PAYMENT_STATUS_PAID", localName: "PAID"},
    {no: 2, name: "ORDER_ITEM_PAYMENT_STATUS_REFUNDED", localName: "REFUNDED"},
    {no: 3, name: "ORDER_ITEM_PAYMENT_STATUS_PARTIALLY_REFUNDED", localName: "PARTIALLY_REFUNDED"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.TransactionType
 */
export const TransactionType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.TransactionType",
  [
    {no: 0, name: "TRANSACTION_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TRANSACTION_TYPE_PURCHASE", localName: "PURCHASE"},
    {no: 2, name: "TRANSACTION_TYPE_REFUND", localName: "REFUND"},
    {no: 3, name: "TRANSACTION_TYPE_EXCHANGE", localName: "EXCHANGE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.TransactionStatus
 */
export const TransactionStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.TransactionStatus",
  [
    {no: 0, name: "TRANSACTION_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TRANSACTION_STATUS_COMPLETE", localName: "COMPLETE"},
    {no: 2, name: "TRANSACTION_STATUS_FAILED", localName: "FAILED"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.TransactionOrderItemAction
 */
export const TransactionOrderItemAction = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.TransactionOrderItemAction",
  [
    {no: 0, name: "TRANSACTION_ORDER_ITEM_ACTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TRANSACTION_ORDER_ITEM_ACTION_PURCHASE", localName: "PURCHASE"},
    {no: 2, name: "TRANSACTION_ORDER_ITEM_ACTION_REFUND", localName: "REFUND"},
    {no: 3, name: "TRANSACTION_ORDER_ITEM_ACTION_UPDATE", localName: "UPDATE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.PaymentMethodType
 */
export const PaymentMethodType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.PaymentMethodType",
  [
    {no: 0, name: "PAYMENT_METHOD_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PAYMENT_METHOD_TYPE_CARD", localName: "CARD"},
    {no: 2, name: "PAYMENT_METHOD_TYPE_CHECK", localName: "CHECK"},
    {no: 3, name: "PAYMENT_METHOD_TYPE_BANK_TRANSFER", localName: "BANK_TRANSFER"},
    {no: 4, name: "PAYMENT_METHOD_TYPE_IN_KIND", localName: "IN_KIND"},
    {no: 5, name: "PAYMENT_METHOD_TYPE_CASH", localName: "CASH"},
    {no: 6, name: "PAYMENT_METHOD_TYPE_NONE", localName: "NONE"},
    {no: 7, name: "PAYMENT_METHOD_TYPE_OTHER", localName: "OTHER"},
    {no: 8, name: "PAYMENT_METHOD_TYPE_STOCK", localName: "STOCK"},
    {no: 9, name: "PAYMENT_METHOD_TYPE_CARD_READER", localName: "CARD_READER"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.SubscriptionStatus
 */
export const SubscriptionStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.SubscriptionStatus",
  [
    {no: 0, name: "SUBSCRIPTION_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SUBSCRIPTION_STATUS_ACTIVE", localName: "ACTIVE"},
    {no: 2, name: "SUBSCRIPTION_STATUS_CANCELED", localName: "CANCELED"},
    {no: 3, name: "SUBSCRIPTION_STATUS_FAILED", localName: "FAILED"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.UserOrgMembershipRole
 */
export const UserOrgMembershipRole = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.UserOrgMembershipRole",
  [
    {no: 0, name: "USER_ORG_MEMBERSHIP_ROLE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "USER_ORG_MEMBERSHIP_ROLE_STAFF", localName: "STAFF"},
    {no: 2, name: "USER_ORG_MEMBERSHIP_ROLE_CONSUMER", localName: "CONSUMER"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.ContactMethod
 */
export const ContactMethod = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.ContactMethod",
  [
    {no: 0, name: "CONTACT_METHOD_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CONTACT_METHOD_EMAIL", localName: "EMAIL"},
    {no: 2, name: "CONTACT_METHOD_PHONE", localName: "PHONE"},
    {no: 3, name: "CONTACT_METHOD_TEXT", localName: "TEXT"},
    {no: 4, name: "CONTACT_METHOD_IN_PERSON", localName: "IN_PERSON"},
    {no: 5, name: "CONTACT_METHOD_MAIL", localName: "MAIL"},
    {no: 6, name: "CONTACT_METHOD_DO_NOT_CALL", localName: "DO_NOT_CALL"},
    {no: 7, name: "CONTACT_METHOD_DO_NOT_MAIL", localName: "DO_NOT_MAIL"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.Title
 */
export const Title = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.Title",
  [
    {no: 0, name: "TITLE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TITLE_MR", localName: "MR"},
    {no: 2, name: "TITLE_MRS", localName: "MRS"},
    {no: 3, name: "TITLE_MS", localName: "MS"},
    {no: 4, name: "TITLE_MX", localName: "MX"},
    {no: 5, name: "TITLE_DR", localName: "DR"},
    {no: 6, name: "TITLE_MISS", localName: "MISS"},
    {no: 7, name: "TITLE_REV", localName: "REV"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.SeatingModel
 */
export const SeatingModel = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.SeatingModel",
  [
    {no: 0, name: "SEATING_MODEL_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SEATING_MODEL_GENERAL_ADMISSION", localName: "GENERAL_ADMISSION"},
    {no: 2, name: "SEATING_MODEL_RESERVED_SEATING", localName: "RESERVED_SEATING"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.ShowStatus
 */
export const ShowStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.ShowStatus",
  [
    {no: 0, name: "SHOW_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SHOW_STATUS_ON_SALE", localName: "ON_SALE"},
    {no: 2, name: "SHOW_STATUS_NOT_ON_SALE", localName: "NOT_ON_SALE"},
    {no: 3, name: "SHOW_STATUS_SOLD_OUT", localName: "SOLD_OUT"},
    {no: 4, name: "SHOW_STATUS_CANCELLED", localName: "CANCELLED"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.ShowOnlineVisibility
 */
export const ShowOnlineVisibility = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.ShowOnlineVisibility",
  [
    {no: 0, name: "SHOW_ONLINE_VISIBILITY_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SHOW_ONLINE_VISIBILITY_VISIBLE", localName: "VISIBLE"},
    {no: 2, name: "SHOW_ONLINE_VISIBILITY_NOT_VISIBLE", localName: "NOT_VISIBLE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.TicketDeliveryMethod
 */
export const TicketDeliveryMethod = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.TicketDeliveryMethod",
  [
    {no: 0, name: "TICKET_DELIVERY_METHOD_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TICKET_DELIVERY_METHOD_ETICKET", localName: "ETICKET"},
    {no: 2, name: "TICKET_DELIVERY_METHOD_BOX_OFFICE", localName: "BOX_OFFICE"},
    {no: 3, name: "TICKET_DELIVERY_METHOD_MAIL", localName: "MAIL"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.TicketBookedStatus
 */
export const TicketBookedStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.TicketBookedStatus",
  [
    {no: 0, name: "TICKET_BOOKED_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TICKET_BOOKED_STATUS_BOOKED", localName: "BOOKED"},
    {no: 2, name: "TICKET_BOOKED_STATUS_RELEASED", localName: "RELEASED"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.ShowtimeRelativeInterval
 */
export const ShowtimeRelativeInterval = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.ShowtimeRelativeInterval",
  [
    {no: 0, name: "SHOWTIME_RELATIVE_INTERVAL_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SHOWTIME_RELATIVE_INTERVAL_BEFORE_SHOWTIME_STARTS", localName: "BEFORE_SHOWTIME_STARTS"},
    {no: 2, name: "SHOWTIME_RELATIVE_INTERVAL_AFTER_SHOWTIME_STARTS", localName: "AFTER_SHOWTIME_STARTS"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.ShowLocationType
 */
export const ShowLocationType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.ShowLocationType",
  [
    {no: 0, name: "SHOW_LOCATION_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SHOW_LOCATION_TYPE_IN_PERSON", localName: "IN_PERSON"},
    {no: 2, name: "SHOW_LOCATION_TYPE_ONLINE", localName: "ONLINE"},
    {no: 3, name: "SHOW_LOCATION_TYPE_TO_BE_ANNOUNCED", localName: "TO_BE_ANNOUNCED"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.ListShowsFilter
 */
export const ListShowsFilter = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.ListShowsFilter",
  [
    {no: 0, name: "LIST_SHOWS_FILTER_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "LIST_SHOWS_FILTER_ALL", localName: "ALL"},
    {no: 2, name: "LIST_SHOWS_FILTER_UPCOMING", localName: "UPCOMING"},
    {no: 3, name: "LIST_SHOWS_FILTER_PAST", localName: "PAST"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.DiscountStatus
 */
export const DiscountStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.DiscountStatus",
  [
    {no: 0, name: "DISCOUNT_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DISCOUNT_STATUS_ACTIVE", localName: "ACTIVE"},
    {no: 2, name: "DISCOUNT_STATUS_INACTIVE", localName: "INACTIVE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.AmountType
 */
export const AmountType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.AmountType",
  [
    {no: 0, name: "AMOUNT_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "AMOUNT_TYPE_FIXED", localName: "FIXED"},
    {no: 2, name: "AMOUNT_TYPE_PERCENTAGE", localName: "PERCENTAGE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.ShowtimeStatus
 */
export const ShowtimeStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.ShowtimeStatus",
  [
    {no: 0, name: "SHOWTIME_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SHOWTIME_STATUS_ON_SALE", localName: "ON_SALE"},
    {no: 2, name: "SHOWTIME_STATUS_CANCELLED", localName: "CANCELLED"},
    {no: 3, name: "SHOWTIME_STATUS_SOLD_OUT", localName: "SOLD_OUT"},
    {no: 4, name: "SHOWTIME_STATUS_NOT_ON_SALE", localName: "NOT_ON_SALE"},
    {no: 5, name: "SHOWTIME_STATUS_INCOMPLETE", localName: "INCOMPLETE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.ShowtimeLabel
 */
export const ShowtimeLabel = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.ShowtimeLabel",
  [
    {no: 0, name: "SHOWTIME_LABEL_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SHOWTIME_LABEL_SELLING_FAST", localName: "SELLING_FAST"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.CartStatus
 */
export const CartStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.CartStatus",
  [
    {no: 0, name: "CART_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CART_STATUS_ACTIVE", localName: "ACTIVE"},
    {no: 2, name: "CART_STATUS_COMPLETED", localName: "COMPLETED"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.TicketPrintStatus
 */
export const TicketPrintStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.TicketPrintStatus",
  [
    {no: 0, name: "TICKET_PRINT_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TICKET_PRINT_STATUS_NOT_PRINTED", localName: "NOT_PRINTED"},
    {no: 2, name: "TICKET_PRINT_STATUS_PRINTED", localName: "PRINTED"},
    {no: 3, name: "TICKET_PRINT_STATUS_PARTIALLY_PRINTED", localName: "PARTIALLY_PRINTED"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.DraftOrderStatus
 */
export const DraftOrderStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.DraftOrderStatus",
  [
    {no: 0, name: "DRAFT_ORDER_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DRAFT_ORDER_STATUS_ACTIVE", localName: "ACTIVE"},
    {no: 2, name: "DRAFT_ORDER_STATUS_COMPLETED", localName: "COMPLETED"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.PledgeStatus
 */
export const PledgeStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.PledgeStatus",
  [
    {no: 0, name: "PLEDGE_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PLEDGE_STATUS_OPEN", localName: "OPEN"},
    {no: 2, name: "PLEDGE_STATUS_COMPLETED", localName: "COMPLETED"},
    {no: 3, name: "PLEDGE_STATUS_OVERDUE", localName: "OVERDUE"},
    {no: 4, name: "PLEDGE_STATUS_WRITE_OFF", localName: "WRITE_OFF"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.ConsumerProfileType
 */
export const ConsumerProfileType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.ConsumerProfileType",
  [
    {no: 0, name: "CONSUMER_PROFILE_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CONSUMER_PROFILE_TYPE_INDIVIDUAL", localName: "INDIVIDUAL"},
    {no: 2, name: "CONSUMER_PROFILE_TYPE_HOUSEHOLD", localName: "HOUSEHOLD"},
    {no: 3, name: "CONSUMER_PROFILE_TYPE_COMPANY", localName: "COMPANY"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.ListPledgesFilter
 */
export const ListPledgesFilter = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.ListPledgesFilter",
  [
    {no: 0, name: "LIST_PLEDGES_FILTER_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "LIST_PLEDGES_FILTER_ALL", localName: "ALL"},
    {no: 2, name: "LIST_PLEDGES_FILTER_OUTSTANDING", localName: "OUTSTANDING"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.AnalyticsDonationTypeFilter
 */
export const AnalyticsDonationTypeFilter = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.AnalyticsDonationTypeFilter",
  [
    {no: 0, name: "ANALYTICS_DONATION_TYPE_FILTER_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ANALYTICS_DONATION_TYPE_FILTER_CASH", localName: "CASH"},
    {no: 2, name: "ANALYTICS_DONATION_TYPE_FILTER_IN_KIND", localName: "IN_KIND"},
    {no: 3, name: "ANALYTICS_DONATION_TYPE_FILTER_STOCK", localName: "STOCK"},
    {no: 4, name: "ANALYTICS_DONATION_TYPE_FILTER_OTHER", localName: "OTHER"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.TimeRangePreset
 */
export const TimeRangePreset = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.TimeRangePreset",
  [
    {no: 0, name: "TIME_RANGE_PRESET_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TIME_RANGE_PRESET_ABSOLUTE", localName: "ABSOLUTE"},
    {no: 2, name: "TIME_RANGE_PRESET_FISCAL_YEAR_TO_DATE", localName: "FISCAL_YEAR_TO_DATE"},
    {no: 3, name: "TIME_RANGE_PRESET_YEAR_TO_DATE", localName: "YEAR_TO_DATE"},
    {no: 4, name: "TIME_RANGE_PRESET_MONTH_TO_DATE", localName: "MONTH_TO_DATE"},
    {no: 5, name: "TIME_RANGE_PRESET_LAST_FISCAL_YEAR", localName: "LAST_FISCAL_YEAR"},
    {no: 6, name: "TIME_RANGE_PRESET_LAST_YEAR", localName: "LAST_YEAR"},
    {no: 7, name: "TIME_RANGE_PRESET_LAST_MONTH", localName: "LAST_MONTH"},
    {no: 8, name: "TIME_RANGE_PRESET_LAST_TWELVE_MONTHS_TO_DATE", localName: "LAST_TWELVE_MONTHS_TO_DATE"},
    {no: 9, name: "TIME_RANGE_PRESET_ALL_TIME", localName: "ALL_TIME"},
    {no: 10, name: "TIME_RANGE_PRESET_LAST_THIRTY_DAYS_TO_DATE", localName: "LAST_THIRTY_DAYS_TO_DATE"},
    {no: 11, name: "TIME_RANGE_PRESET_TODAY", localName: "TODAY"},
    {no: 12, name: "TIME_RANGE_PRESET_THIS_WEEKEND", localName: "THIS_WEEKEND"},
    {no: 13, name: "TIME_RANGE_PRESET_THIS_MONTH", localName: "THIS_MONTH"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.DraftOrderItemAction
 */
export const DraftOrderItemAction = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.DraftOrderItemAction",
  [
    {no: 0, name: "DRAFT_ORDER_ITEM_ACTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DRAFT_ORDER_ITEM_ACTION_PURCHASE", localName: "PURCHASE"},
    {no: 2, name: "DRAFT_ORDER_ITEM_ACTION_REFUND", localName: "REFUND"},
    {no: 3, name: "DRAFT_ORDER_ITEM_ACTION_UPDATE", localName: "UPDATE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.SalesChannel
 */
export const SalesChannel = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.SalesChannel",
  [
    {no: 0, name: "SALES_CHANNEL_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SALES_CHANNEL_BOX_OFFICE", localName: "BOX_OFFICE"},
    {no: 2, name: "SALES_CHANNEL_ONLINE", localName: "ONLINE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.ProductType
 */
export const ProductType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.ProductType",
  [
    {no: 0, name: "PRODUCT_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PRODUCT_TYPE_SINGLE_SHOW", localName: "SINGLE_SHOW"},
    {no: 2, name: "PRODUCT_TYPE_SEASON_PACKAGE", localName: "SEASON_PACKAGE"},
    {no: 3, name: "PRODUCT_TYPE_MEMBERSHIP", localName: "MEMBERSHIP"},
    {no: 4, name: "PRODUCT_TYPE_MERCHANDISE", localName: "MERCHANDISE"},
    {no: 5, name: "PRODUCT_TYPE_SHOWTIME", localName: "SHOWTIME"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.SeasonPackageStatus
 */
export const SeasonPackageStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.SeasonPackageStatus",
  [
    {no: 0, name: "SEASON_PACKAGE_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SEASON_PACKAGE_STATUS_ON_SALE", localName: "ON_SALE"},
    {no: 2, name: "SEASON_PACKAGE_STATUS_NOT_ON_SALE", localName: "NOT_ON_SALE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.DraftOrderIntent
 */
export const DraftOrderIntent = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.DraftOrderIntent",
  [
    {no: 0, name: "DRAFT_ORDER_INTENT_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DRAFT_ORDER_INTENT_NEW", localName: "NEW"},
    {no: 2, name: "DRAFT_ORDER_INTENT_REFUND", localName: "REFUND"},
    {no: 3, name: "DRAFT_ORDER_INTENT_EXCHANGE", localName: "EXCHANGE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.ListMembershipFilter
 */
export const ListMembershipFilter = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.ListMembershipFilter",
  [
    {no: 0, name: "LIST_MEMBERSHIP_FILTER_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "LIST_MEMBERSHIP_FILTER_ALL", localName: "ALL"},
    {no: 2, name: "LIST_MEMBERSHIP_FILTER_UPCOMING", localName: "UPCOMING"},
    {no: 3, name: "LIST_MEMBERSHIP_FILTER_PAST", localName: "PAST"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.BenefitPeriodType
 */
export const BenefitPeriodType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.BenefitPeriodType",
  [
    {no: 0, name: "BENEFIT_PERIOD_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "BENEFIT_PERIOD_TYPE_FIXED_DURATION", localName: "FIXED_DURATION"},
    {no: 2, name: "BENEFIT_PERIOD_TYPE_FIXED_END_DATE", localName: "FIXED_END_DATE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.RevenueType
 */
export const RevenueType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.RevenueType",
  [
    {no: 0, name: "REVENUE_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "REVENUE_TYPE_EARNED", localName: "EARNED"},
    {no: 2, name: "REVENUE_TYPE_CONTRIBUTED", localName: "CONTRIBUTED"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.MembershipStatus
 */
export const MembershipStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.MembershipStatus",
  [
    {no: 0, name: "MEMBERSHIP_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MEMBERSHIP_STATUS_ON_SALE", localName: "ON_SALE"},
    {no: 2, name: "MEMBERSHIP_STATUS_NOT_ON_SALE", localName: "NOT_ON_SALE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.DiscountApplyMode
 */
export const DiscountApplyMode = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.DiscountApplyMode",
  [
    {no: 0, name: "DISCOUNT_APPLY_MODE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DISCOUNT_APPLY_MODE_CODE", localName: "CODE"},
    {no: 2, name: "DISCOUNT_APPLY_MODE_AUTOMATIC", localName: "AUTOMATIC"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.TicketDesignTemplateGenerationMode
 */
export const TicketDesignTemplateGenerationMode = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.TicketDesignTemplateGenerationMode",
  [
    {no: 0, name: "TICKET_DESIGN_TEMPLATE_GENERATION_MODE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TICKET_DESIGN_TEMPLATE_GENERATION_MODE_A4", localName: "A4"},
    {no: 2, name: "TICKET_DESIGN_TEMPLATE_GENERATION_MODE_FULL_PAGE", localName: "FULL_PAGE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.DiscountSource
 */
export const DiscountSource = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.DiscountSource",
  [
    {no: 0, name: "DISCOUNT_SOURCE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DISCOUNT_SOURCE_INPUT", localName: "INPUT"},
    {no: 2, name: "DISCOUNT_SOURCE_LINK", localName: "LINK"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.ListSeasonPackageReportType
 */
export const ListSeasonPackageReportType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.ListSeasonPackageReportType",
  [
    {no: 0, name: "LIST_SEASON_PACKAGE_REPORT_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "LIST_SEASON_PACKAGE_REPORT_TYPE_PER_PACKAGE", localName: "PER_PACKAGE"},
    {no: 2, name: "LIST_SEASON_PACKAGE_REPORT_TYPE_PER_ITEM", localName: "PER_ITEM"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.AnalyticsPaymentTypeFilter
 */
export const AnalyticsPaymentTypeFilter = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.AnalyticsPaymentTypeFilter",
  [
    {no: 0, name: "ANALYTICS_PAYMENT_TYPE_FILTER_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ANALYTICS_PAYMENT_TYPE_FILTER_CREDIT_CARD", localName: "CREDIT_CARD"},
    {no: 2, name: "ANALYTICS_PAYMENT_TYPE_FILTER_NON_CREDIT_CARD", localName: "NON_CREDIT_CARD"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.AnalyticsDateTypeFilter
 */
export const AnalyticsDateTypeFilter = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.AnalyticsDateTypeFilter",
  [
    {no: 0, name: "ANALYTICS_DATE_TYPE_FILTER_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ANALYTICS_DATE_TYPE_FILTER_TRANSACTION_DATE", localName: "TRANSACTION_DATE"},
    {no: 2, name: "ANALYTICS_DATE_TYPE_FILTER_PAYOUT_DATE", localName: "PAYOUT_DATE"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.FulfillmentStatus
 */
export const FulfillmentStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.FulfillmentStatus",
  [
    {no: 0, name: "FULFILLMENT_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "FULFILLMENT_STATUS_FULFILLED", localName: "FULFILLED"},
    {no: 2, name: "FULFILLMENT_STATUS_UNFULFILLED", localName: "UNFULFILLED"},
    {no: 3, name: "FULFILLMENT_STATUS_PARTIALLY_FULFILLED", localName: "PARTIALLY_FULFILLED"},
    {no: 4, name: "FULFILLMENT_STATUS_NO_FULFILLMENT_NEEDED", localName: "NO_FULFILLMENT_NEEDED"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.PaymentProcessor
 */
export const PaymentProcessor = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.PaymentProcessor",
  [
    {no: 0, name: "PAYMENT_PROCESSOR_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PAYMENT_PROCESSOR_TILLED", localName: "TILLED"},
    {no: 2, name: "PAYMENT_PROCESSOR_FINIX", localName: "FINIX"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.FinixPaymentInstrumentType
 */
export const FinixPaymentInstrumentType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.FinixPaymentInstrumentType",
  [
    {no: 0, name: "FINIX_PAYMENT_INSTRUMENT_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "FINIX_PAYMENT_INSTRUMENT_TYPE_TOKEN", localName: "TOKEN"},
    {no: 2, name: "FINIX_PAYMENT_INSTRUMENT_TYPE_APPLE_PAY", localName: "APPLE_PAY"},
    {no: 3, name: "FINIX_PAYMENT_INSTRUMENT_TYPE_GOOGLE_PAY", localName: "GOOGLE_PAY"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.HardwareDeviceType
 */
export const HardwareDeviceType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.HardwareDeviceType",
  [
    {no: 0, name: "HARDWARE_DEVICE_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "HARDWARE_DEVICE_TYPE_FINIX_CARD_READER", localName: "FINIX_CARD_READER"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.HardwareDeviceStatus
 */
export const HardwareDeviceStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.HardwareDeviceStatus",
  [
    {no: 0, name: "HARDWARE_DEVICE_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "HARDWARE_DEVICE_STATUS_CONNECTED", localName: "CONNECTED"},
    {no: 2, name: "HARDWARE_DEVICE_STATUS_DISCONNECTED", localName: "DISCONNECTED"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.WebAnalyticsPlatform
 */
export const WebAnalyticsPlatform = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.WebAnalyticsPlatform",
  [
    {no: 0, name: "WEB_ANALYTICS_PLATFORM_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "WEB_ANALYTICS_PLATFORM_GOOGLE_ANALYTICS", localName: "GOOGLE_ANALYTICS"},
    {no: 2, name: "WEB_ANALYTICS_PLATFORM_GOOGLE_TAG_MANAGER", localName: "GOOGLE_TAG_MANAGER"},
    {no: 3, name: "WEB_ANALYTICS_PLATFORM_META_PIXEL", localName: "META_PIXEL"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.TagType
 */
export const TagType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.TagType",
  [
    {no: 0, name: "TAG_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TAG_TYPE_CONSTITUENT", localName: "CONSTITUENT"},
    {no: 2, name: "TAG_TYPE_SHOW", localName: "SHOW"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.EmailType
 */
export const EmailType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.EmailType",
  [
    {no: 0, name: "EMAIL_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "EMAIL_TYPE_PRE_SHOW", localName: "PRE_SHOW"},
    {no: 2, name: "EMAIL_TYPE_POST_SHOW", localName: "POST_SHOW"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.FormQuestionPlacement
 */
export const FormQuestionPlacement = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.FormQuestionPlacement",
  [
    {no: 0, name: "FORM_QUESTION_PLACEMENT_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "FORM_QUESTION_PLACEMENT_PRE_CHECKOUT", localName: "PRE_CHECKOUT"},
    {no: 2, name: "FORM_QUESTION_PLACEMENT_POST_CHECKOUT", localName: "POST_CHECKOUT"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.FormQuestionType
 */
export const FormQuestionType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.FormQuestionType",
  [
    {no: 0, name: "FORM_QUESTION_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "FORM_QUESTION_TYPE_SHORT_TEXT", localName: "SHORT_TEXT"},
    {no: 2, name: "FORM_QUESTION_TYPE_RADIO_GROUP", localName: "RADIO_GROUP"},
    {no: 3, name: "FORM_QUESTION_TYPE_CHECKBOX_GROUP", localName: "CHECKBOX_GROUP"},
    {no: 4, name: "FORM_QUESTION_TYPE_BOOLEAN", localName: "BOOLEAN"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.FormQuestionScope
 */
export const FormQuestionScope = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.FormQuestionScope",
  [
    {no: 0, name: "FORM_QUESTION_SCOPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "FORM_QUESTION_SCOPE_ORDER", localName: "ORDER"},
    {no: 2, name: "FORM_QUESTION_SCOPE_ORDER_ITEM", localName: "ORDER_ITEM"},
  ],
);

/**
 * @generated from enum cuebox.common.v1.RenewalStatus
 */
export const RenewalStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.common.v1.RenewalStatus",
  [
    {no: 0, name: "RENEWAL_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "RENEWAL_STATUS_NOT_RENEWED", localName: "NOT_RENEWED"},
    {no: 2, name: "RENEWAL_STATUS_RENEWED", localName: "RENEWED"},
    {no: 3, name: "RENEWAL_STATUS_RELEASED", localName: "RELEASED"},
  ],
);

/**
 * @generated from message cuebox.common.v1.PhoneNumber
 */
export const PhoneNumber = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.common.v1.PhoneNumber",
  () => [
    { no: 1, name: "international_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.common.v1.Money
 */
export const Money = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.common.v1.Money",
  () => [
    { no: 1, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.common.v1.AmountModifier
 */
export const AmountModifier = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.common.v1.AmountModifier",
  () => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(AmountType) },
    { no: 2, name: "fixed_amount", kind: "message", T: Money },
    { no: 3, name: "percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.common.v1.Asset
 */
export const Asset = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.common.v1.Asset",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "public_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * A general purpose file message for use in downloads on the frontend.
 *
 * @generated from message cuebox.common.v1.File
 */
export const File = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.common.v1.File",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message cuebox.common.v1.Address
 */
export const Address = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.common.v1.Address",
  () => [
    { no: 1, name: "line1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "line2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subdivision_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.common.v1.Tribute
 */
export const Tribute = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.common.v1.Tribute",
  () => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(TributeType) },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.common.v1.TimeRange
 */
export const TimeRange = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.common.v1.TimeRange",
  () => [
    { no: 1, name: "start", kind: "message", T: Timestamp },
    { no: 2, name: "end", kind: "message", T: Timestamp },
    { no: 3, name: "preset", kind: "enum", T: proto3.getEnumType(TimeRangePreset) },
  ],
);

/**
 * @generated from message cuebox.common.v1.PaymentMethod
 */
export const PaymentMethod = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.common.v1.PaymentMethod",
  () => [
    { no: 1, name: "payment_method_type", kind: "enum", T: proto3.getEnumType(PaymentMethodType) },
    { no: 2, name: "tilled_payment_method_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "finix_payment_instrument_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "check_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "in_kind_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "bank_transfer_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "other_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.common.v1.Card
 */
export const Card = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.common.v1.Card",
  () => [
    { no: 1, name: "brand", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last4", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expiration_month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "expiration_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "holder_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

