// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/seat.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Only seatsio_bookable_object_id is required for SaveItems request
 *
 * @generated from message cuebox.services.staff.v1.Seat
 */
export const Seat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Seat",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "seatsio_bookable_object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "section_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "row_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "seat_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_accessible", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SeatHold
 */
export const SeatHold = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeatHold",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "consumer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "consumer_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "showtime_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "seatsio_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "seat_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "seatsio_bookable_object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "seat_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "show_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "showtime_starts_at", kind: "message", T: Timestamp },
    { no: 13, name: "showtime_timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SeatHoldOptions
 */
export const SeatHoldOptions = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeatHoldOptions",
  () => [
    { no: 1, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "showtime_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "seatsio_bookable_object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateSeatHoldsRequest
 */
export const CreateSeatHoldsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateSeatHoldsRequest",
  () => [
    { no: 1, name: "holds", kind: "message", T: SeatHoldOptions, repeated: true },
    { no: 2, name: "should_overwrite_existing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateSeatHoldsResponse
 */
export const CreateSeatHoldsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateSeatHoldsResponse",
  () => [
    { no: 1, name: "holds", kind: "message", T: SeatHold, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ReleaseSeatHoldsRequest
 */
export const ReleaseSeatHoldsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ReleaseSeatHoldsRequest",
  () => [
    { no: 1, name: "seat_hold_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ReleaseSeatHoldsResponse
 */
export const ReleaseSeatHoldsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ReleaseSeatHoldsResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ReleaseShowtimesSeatHoldsRequest
 */
export const ReleaseShowtimesSeatHoldsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ReleaseShowtimesSeatHoldsRequest",
  () => [
    { no: 1, name: "showtime_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ReleaseShowtimesSeatHoldsResponse
 */
export const ReleaseShowtimesSeatHoldsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ReleaseShowtimesSeatHoldsResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListShowtimesSeatHoldsRequest
 */
export const ListShowtimesSeatHoldsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListShowtimesSeatHoldsRequest",
  () => [
    { no: 1, name: "showtime_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListShowtimesSeatHoldsResponse
 */
export const ListShowtimesSeatHoldsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListShowtimesSeatHoldsResponse",
  () => [
    { no: 1, name: "holds", kind: "message", T: SeatHold, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSeatHoldsForConsumerProfilesRequest
 */
export const ListSeatHoldsForConsumerProfilesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSeatHoldsForConsumerProfilesRequest",
  () => [
    { no: 1, name: "consumer_profile_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSeatHoldsForConsumerProfilesResponse
 */
export const ListSeatHoldsForConsumerProfilesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSeatHoldsForConsumerProfilesResponse",
  () => [
    { no: 1, name: "seat_holds", kind: "message", T: SeatHold, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SeatsioHoldToken
 */
export const SeatsioHoldToken = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeatsioHoldToken",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "expires_at", kind: "message", T: Timestamp },
  ],
);

