import type { RecursiveObject, SemanticValue } from '@chakra-ui/react';

export type SemanticValuesDef = {
  active?: SemanticValue<string>;
  base: SemanticValue<string>;
  darker?: SemanticValue<string>;
  disabled?: SemanticValue<string>;
  lighter?: SemanticValue<string>;
};

type SemanticPropertyKeys =
  | 'primary'
  | 'secondary'
  | 'backgrounds'
  | 'borders'
  | 'error'
  | 'sidebar'
  | 'text';

export type SemanticTokens = RecursiveObject<
  Record<
    string | SemanticPropertyKeys,
    SemanticValue<string> | SemanticValuesDef
  >
>;

const defaultTextBase = '#25292e';
const defaultTextLighter = '#6e757c';
const defaultContentBg = '#f4f5f6';
const defaultBorderBase = '#e5e7ea';

// Chakra provides its own set of defaults that are turned into CSS vars and used around the theme.
// We mostly want to leave these alone aside from specifying our default text colors.
const chakraOverrides = {
  'chakra-body-text': {
    _light: defaultTextBase,
    _dark: 'whiteAlpha.900',
  },
  'chakra-body-bg': {
    _light: 'white',
    _dark: 'gray.800',
  },
  'chakra-border-color': {
    _light: defaultBorderBase,
    _dark: 'whiteAlpha.300',
  },
  'chakra-subtle-bg': {
    _light: defaultContentBg,
    _dark: 'gray.700',
  },
  'chakra-subtle-text': {
    _light: defaultTextLighter,
    _dark: 'gray.400',
  },
  'chakra-placeholder-color': {
    _light: defaultTextLighter,
    _dark: 'whiteAlpha.400',
  },
};

// Our map of custom tokens attempts to define important concepts like primary and secondary brand colors, common background/borders, etc.
// This helps us to add meaning to styles in the theme and around components instead of remembering what "dark.300" pertains to.
// We also override some of these colors on the consumer side to e.g. change button highlights, but most of these apply to both layouts.
export const semanticTokens: SemanticTokens = {
  colors: {
    ...chakraOverrides,
    primary: {
      base: 'staff.500',
      active: 'staff.400',
      darker: 'staff.600',
      disabled: 'staff.100',
      // Not all of these are defined, but if we ever add them to the original palette, we can use them here.
      '900': 'staff.900',
      '800': 'staff.800',
      '700': 'staff.700',
      '600': 'staff.600',
      '500': 'staff.500',
      '400': 'staff.400',
      '300': 'staff.300',
      '200': 'staff.200',
      '100': 'staff.100',
      '50': 'staff.50',
    },
    secondary: {
      base: defaultBorderBase,
      active: defaultContentBg,
      disabled: '#9ca3ab',
    },
    backgrounds: {
      base: defaultContentBg,
      lighter: '#fafafa',
    },
    borders: {
      base: defaultBorderBase,
      lighter: '#d2d9f7',
    },
    error: '#df5151',
    sidebar: {
      background: '#0c293d',
      highlight: '#425360',
      text: '#a0a8b0',
    },
    text: {
      base: defaultTextBase,
      lighter: defaultTextLighter,
      disabled: '#9ca3ab',
    },
  },
};
