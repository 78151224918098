// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/analytics.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { AcknowledgmentStatus, Address, AnalyticsDateTypeFilter, AnalyticsDonationTypeFilter, AnalyticsPaymentTypeFilter, FormQuestionScope, ListSeasonPackageReportType, Money, OrderItemPaymentStatus, OrderItemType, OrderPaymentStatus, PaymentMethod, PaymentMethodType, PhoneNumber, ProductType, RevenueType, SalesChannel, SubscriptionFrequency, SubscriptionStatus, TicketBookedStatus, TicketDeliveryMethod, TimeRange, TransactionOrderItemAction } from "../../../common/v1/common_pb.js";
import { FormQuestionResponse } from "./form_question_pb.js";

/**
 * Donations Metrics
 *
 * @generated from message cuebox.services.staff.v1.GetDonationMetricsRequest
 */
export const GetDonationMetricsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetDonationMetricsRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "donation_type_filters", kind: "enum", T: proto3.getEnumType(AnalyticsDonationTypeFilter), repeated: true },
    { no: 3, name: "current_fiscal_year_start_date", kind: "message", T: Timestamp },
    { no: 4, name: "fund_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "campaign_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetDonationMetricsResponse
 */
export const GetDonationMetricsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetDonationMetricsResponse",
  () => [
    { no: 1, name: "current_period_time_range", kind: "message", T: TimeRange },
    { no: 2, name: "previous_period_time_range", kind: "message", T: TimeRange },
    { no: 3, name: "metrics", kind: "message", T: DonationMetrics },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DonationMetrics
 */
export const DonationMetrics = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DonationMetrics",
  () => [
    { no: 1, name: "donations_received", kind: "message", T: DonationReceived },
    { no: 2, name: "donations_count", kind: "message", T: DonationsCount },
    { no: 3, name: "average_donation", kind: "message", T: AverageDonation },
    { no: 4, name: "fiscal_year_to_date_donation_to_goal", kind: "message", T: FiscalYearToDateDonationToGoal },
    { no: 5, name: "donors_count", kind: "message", T: DonorsCount },
    { no: 6, name: "first_time_donors_count", kind: "message", T: FirstTimeDonorsCount },
    { no: 7, name: "total_pledges", kind: "message", T: TotalPledges },
    { no: 8, name: "outstanding_pledges", kind: "message", T: OutstandingPledges },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DonationReceived
 */
export const DonationReceived = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DonationReceived",
  () => [
    { no: 1, name: "current_period_amount", kind: "message", T: Money },
    { no: 2, name: "previous_period_amount", kind: "message", T: Money },
    { no: 3, name: "percentage_delta_from_previous_period", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DonationsCount
 */
export const DonationsCount = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DonationsCount",
  () => [
    { no: 1, name: "current_period_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "previous_period_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "percentage_delta_from_previous_period", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AverageDonation
 */
export const AverageDonation = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AverageDonation",
  () => [
    { no: 1, name: "current_period_amount", kind: "message", T: Money },
    { no: 2, name: "previous_period_amount", kind: "message", T: Money },
    { no: 3, name: "percentage_delta_from_previous_period", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * There is no year over year comparison for Fiscal Year to Date Donation to Goal Metric
 *
 * @generated from message cuebox.services.staff.v1.FiscalYearToDateDonationToGoal
 */
export const FiscalYearToDateDonationToGoal = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FiscalYearToDateDonationToGoal",
  () => [
    { no: 1, name: "donated_amount", kind: "message", T: Money },
    { no: 2, name: "goal_amount", kind: "message", T: Money },
    { no: 3, name: "percentage_to_goal", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DonorsCount
 */
export const DonorsCount = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DonorsCount",
  () => [
    { no: 1, name: "current_period_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "previous_period_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "percentage_delta_from_previous_period", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.FirstTimeDonorsCount
 */
export const FirstTimeDonorsCount = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FirstTimeDonorsCount",
  () => [
    { no: 1, name: "current_period_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "previous_period_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "percentage_delta_from_previous_period", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TotalPledges
 */
export const TotalPledges = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TotalPledges",
  () => [
    { no: 1, name: "current_period_total_amount", kind: "message", T: Money },
    { no: 2, name: "previous_period_total_amount", kind: "message", T: Money },
    { no: 3, name: "percentage_delta_from_previous_period", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.OutstandingPledges
 */
export const OutstandingPledges = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.OutstandingPledges",
  () => [
    { no: 1, name: "current_period_total_amount", kind: "message", T: Money },
    { no: 2, name: "previous_period_total_amount", kind: "message", T: Money },
    { no: 3, name: "percentage_delta_from_previous_period", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * Donations Received Over Time Chart
 *
 * @generated from message cuebox.services.staff.v1.GetDonationReceivedChartRequest
 */
export const GetDonationReceivedChartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetDonationReceivedChartRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "donation_type_filters", kind: "enum", T: proto3.getEnumType(AnalyticsDonationTypeFilter), repeated: true },
    { no: 3, name: "campaign_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "fund_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetDonationReceivedChartResponse
 */
export const GetDonationReceivedChartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetDonationReceivedChartResponse",
  () => [
    { no: 1, name: "current_period_table", kind: "message", T: DonationReceivedTable },
    { no: 2, name: "previous_period_table", kind: "message", T: DonationReceivedTable },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DonationReceivedTable
 */
export const DonationReceivedTable = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DonationReceivedTable",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "rows", kind: "message", T: DonationReceivedRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DonationReceivedRow
 */
export const DonationReceivedRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DonationReceivedRow",
  () => [
    { no: 1, name: "time", kind: "message", T: Timestamp },
    { no: 2, name: "amount", kind: "message", T: Money },
  ],
);

/**
 * Top Campaigns Chart
 *
 * @generated from message cuebox.services.staff.v1.GetTopCampaignsChartRequest
 */
export const GetTopCampaignsChartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetTopCampaignsChartRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "donation_type_filters", kind: "enum", T: proto3.getEnumType(AnalyticsDonationTypeFilter), repeated: true },
    { no: 3, name: "fund_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "campaign_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetTopCampaignsChartResponse
 */
export const GetTopCampaignsChartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetTopCampaignsChartResponse",
  () => [
    { no: 1, name: "table", kind: "message", T: TopCampaignsTable },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TopCampaignsTable
 */
export const TopCampaignsTable = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TopCampaignsTable",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "rows", kind: "message", T: TopCampaignsRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TopCampaignsRow
 */
export const TopCampaignsRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TopCampaignsRow",
  () => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "campaign_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "amount", kind: "message", T: Money },
    { no: 4, name: "percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * Top Funds Chart
 *
 * @generated from message cuebox.services.staff.v1.GetTopFundsChartRequest
 */
export const GetTopFundsChartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetTopFundsChartRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "donation_type_filters", kind: "enum", T: proto3.getEnumType(AnalyticsDonationTypeFilter), repeated: true },
    { no: 3, name: "fund_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "campaign_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetTopFundsChartResponse
 */
export const GetTopFundsChartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetTopFundsChartResponse",
  () => [
    { no: 1, name: "table", kind: "message", T: TopFundsTable },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TopFundsTable
 */
export const TopFundsTable = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TopFundsTable",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "rows", kind: "message", T: TopFundsRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TopFundsRow
 */
export const TopFundsRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TopFundsRow",
  () => [
    { no: 1, name: "fund_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fund_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "amount", kind: "message", T: Money },
    { no: 4, name: "percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * Donor Retention Rate Chart
 *
 * @generated from message cuebox.services.staff.v1.GetDonorRetentionRateChartResponse
 */
export const GetDonorRetentionRateChartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetDonorRetentionRateChartResponse",
  () => [
    { no: 1, name: "retained_donors_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "lapsed_donors_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "retained_donors_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "lapsed_donors_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetDonorRetentionRateChartRequest
 */
export const GetDonorRetentionRateChartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetDonorRetentionRateChartRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "donation_type_filters", kind: "enum", T: proto3.getEnumType(AnalyticsDonationTypeFilter), repeated: true },
    { no: 3, name: "campaign_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "fund_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AnalyticsTicketingFilter
 */
export const AnalyticsTicketingFilter = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AnalyticsTicketingFilter",
  () => [
    { no: 1, name: "showtime_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * Ticketing Metrics
 *
 * @generated from message cuebox.services.staff.v1.GetTicketingMetricsRequest
 */
export const GetTicketingMetricsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetTicketingMetricsRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "ticketing_filter", kind: "message", T: AnalyticsTicketingFilter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetTicketingMetricsResponse
 */
export const GetTicketingMetricsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetTicketingMetricsResponse",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "metrics", kind: "message", T: TicketingMetrics },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TicketingMetrics
 */
export const TicketingMetrics = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketingMetrics",
  () => [
    { no: 1, name: "tickets_sold", kind: "message", T: TicketsSold },
    { no: 2, name: "ticket_sales", kind: "message", T: TicketSales },
    { no: 3, name: "average_ticket_price", kind: "message", T: AverageTicketPrice },
    { no: 4, name: "buyers_count", kind: "message", T: BuyersCount },
    { no: 5, name: "first_time_buyers_count", kind: "message", T: FirstTimeBuyersCount },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TicketsSold
 */
export const TicketsSold = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketsSold",
  () => [
    { no: 1, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "sold_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "percentage_ticket_sold", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TicketSales
 */
export const TicketSales = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketSales",
  () => [
    { no: 1, name: "total_sales_amount", kind: "message", T: Money },
    { no: 2, name: "sales_goal_amount", kind: "message", T: Money },
    { no: 3, name: "percentage_sales_to_goal", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AverageTicketPrice
 */
export const AverageTicketPrice = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AverageTicketPrice",
  () => [
    { no: 1, name: "average_amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.BuyersCount
 */
export const BuyersCount = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.BuyersCount",
  () => [
    { no: 1, name: "buyers_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.FirstTimeBuyersCount
 */
export const FirstTimeBuyersCount = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FirstTimeBuyersCount",
  () => [
    { no: 1, name: "first_time_buyers_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * Ticketing Data Chart
 *
 * @generated from message cuebox.services.staff.v1.GetTicketingDataChartRequest
 */
export const GetTicketingDataChartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetTicketingDataChartRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "ticketing_filter", kind: "message", T: AnalyticsTicketingFilter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetTicketingDataChartResponse
 */
export const GetTicketingDataChartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetTicketingDataChartResponse",
  () => [
    { no: 1, name: "current_period_table", kind: "message", T: TicketingDataTable },
    { no: 2, name: "previous_period_table", kind: "message", T: TicketingDataTable },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TicketingDataTable
 */
export const TicketingDataTable = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketingDataTable",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "rows", kind: "message", T: TicketingDataRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TicketingDataRow
 */
export const TicketingDataRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketingDataRow",
  () => [
    { no: 1, name: "time", kind: "message", T: Timestamp },
    { no: 2, name: "cumulative_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "average_amount", kind: "message", T: Money },
  ],
);

/**
 * Tickets Sold By Ticket Type Chart
 *
 * @generated from message cuebox.services.staff.v1.GetTicketsSoldByTicketTypeChartRequest
 */
export const GetTicketsSoldByTicketTypeChartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetTicketsSoldByTicketTypeChartRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "ticketing_filter", kind: "message", T: AnalyticsTicketingFilter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetTicketsSoldByTicketTypeChartResponse
 */
export const GetTicketsSoldByTicketTypeChartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetTicketsSoldByTicketTypeChartResponse",
  () => [
    { no: 1, name: "table", kind: "message", T: TicketsSoldByTicketTypeTable },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TicketsSoldByTicketTypeTable
 */
export const TicketsSoldByTicketTypeTable = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketsSoldByTicketTypeTable",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "rows", kind: "message", T: TicketsSoldByTicketTypeRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TicketsSoldByTicketTypeRow
 */
export const TicketsSoldByTicketTypeRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketsSoldByTicketTypeRow",
  () => [
    { no: 1, name: "ticket_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_type_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sold_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.LYBUNTTableRow
 */
export const LYBUNTTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.LYBUNTTableRow",
  () => [
    { no: 1, name: "household_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "household_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_donation_date", kind: "message", T: Timestamp },
    { no: 4, name: "last_donation_amount", kind: "message", T: Money },
    { no: 5, name: "lifetime_donation_amount", kind: "message", T: Money },
    { no: 6, name: "primary_contact_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "primary_contact_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "primary_contact_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "primary_contact_phone", kind: "message", T: PhoneNumber },
    { no: 10, name: "household_address", kind: "message", T: Address },
    { no: 11, name: "donor_formal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "donor_informal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "donor_recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "donor_formal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "donor_informal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListLYBUNTTableRowsRequest
 */
export const ListLYBUNTTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListLYBUNTTableRowsRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListLYBUNTTableRowsResponse
 */
export const ListLYBUNTTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListLYBUNTTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: LYBUNTTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.KeyAmountTableRow
 */
export const KeyAmountTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.KeyAmountTableRow",
  () => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTicketSalesSummaryTableRowsRequest
 */
export const ListTicketSalesSummaryTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTicketSalesSummaryTableRowsRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "ticketing_filter", kind: "message", T: AnalyticsTicketingFilter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTicketSalesSummaryTableRowsResponse
 */
export const ListTicketSalesSummaryTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTicketSalesSummaryTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: KeyAmountTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TransactionTableRow
 */
export const TransactionTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TransactionTableRow",
  () => [
    { no: 1, name: "transaction_order_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "transaction_at", kind: "message", T: Timestamp },
    { no: 3, name: "constituent_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "constituent_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "constituent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "item_type", kind: "enum", T: proto3.getEnumType(OrderItemType) },
    { no: 7, name: "item_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 9, name: "item_price_amount", kind: "message", T: Money },
    { no: 10, name: "fee_amount", kind: "message", T: Money },
    { no: 11, name: "net_amount", kind: "message", T: Money },
    { no: 12, name: "is_credit_card_fee_available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "credit_card_fee_amount", kind: "message", T: Money },
    { no: 14, name: "payout_amount", kind: "message", T: Money },
    { no: 15, name: "action", kind: "enum", T: proto3.getEnumType(TransactionOrderItemAction) },
    { no: 16, name: "payment_method_type", kind: "enum", T: proto3.getEnumType(PaymentMethodType) },
    { no: 17, name: "sales_channel", kind: "enum", T: proto3.getEnumType(SalesChannel) },
    { no: 18, name: "donation_at", kind: "message", T: Timestamp },
    { no: 19, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "campaign_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "fund_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "fund_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "subscription_frequency", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency) },
    { no: 24, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "show_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "showtime_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "showtime_starts_at", kind: "message", T: Timestamp },
    { no: 28, name: "ticket_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 29, name: "ticket_type_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 30, name: "seat", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 32, name: "revenue_type", kind: "enum", T: proto3.getEnumType(RevenueType) },
    { no: 33, name: "payout_at", kind: "message", T: Timestamp },
    { no: 34, name: "general_ledger_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 35, name: "payment_method", kind: "message", T: PaymentMethod },
    { no: 36, name: "transaction_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 37, name: "order_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 38, name: "parent_order_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 39, name: "parent_item_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 40, name: "discount_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 41, name: "household_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 42, name: "subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 43, name: "billing_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 44, name: "billing_phone_number", kind: "message", T: PhoneNumber },
    { no: 45, name: "billing_address", kind: "message", T: Address },
    { no: 46, name: "constituent_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 47, name: "constituent_phone_number", kind: "message", T: PhoneNumber },
    { no: 48, name: "constituent_address", kind: "message", T: Address },
    { no: 49, name: "constituent_formal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "constituent_informal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 51, name: "constituent_recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 52, name: "constituent_formal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 53, name: "constituent_informal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 54, name: "household_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 55, name: "appeal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 56, name: "appeal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 57, name: "item_current_payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 58, name: "ticket_delivery_method", kind: "enum", T: proto3.getEnumType(TicketDeliveryMethod) },
    { no: 59, name: "ticket_booked_status", kind: "enum", T: proto3.getEnumType(TicketBookedStatus) },
    { no: 60, name: "item_detail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TransactionTableFilters
 */
export const TransactionTableFilters = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TransactionTableFilters",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTransactionTableRowsRequest
 */
export const ListTransactionTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTransactionTableRowsRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "filters", kind: "message", T: TransactionTableFilters },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTransactionTableRowsResponse
 */
export const ListTransactionTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTransactionTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: TransactionTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TicketTypeQuantity
 */
export const TicketTypeQuantity = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketTypeQuantity",
  () => [
    { no: 1, name: "ticket_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_type_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TicketBuyerTableRow
 */
export const TicketBuyerTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketBuyerTableRow",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_created_at", kind: "message", T: Timestamp },
    { no: 3, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "consumer_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "consumer_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "consumer_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "consumer_phone_number", kind: "message", T: PhoneNumber },
    { no: 8, name: "billing_address", kind: "message", T: Address },
    { no: 9, name: "shipping_address", kind: "message", T: Address },
    { no: 10, name: "ticket_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "net_amount", kind: "message", T: Money },
    { no: 12, name: "order_notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 14, name: "ticket_delivery_methods", kind: "enum", T: proto3.getEnumType(TicketDeliveryMethod), repeated: true },
    { no: 15, name: "show_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "showtime_starts_at", kind: "message", T: Timestamp },
    { no: 17, name: "net_donation_amount", kind: "message", T: Money },
    { no: 18, name: "checked_in_ticket_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 19, name: "ticket_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 20, name: "seat_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 21, name: "ticket_type_quantities", kind: "message", T: TicketTypeQuantity, repeated: true },
    { no: 22, name: "showtime_attendance_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTicketBuyerTableRowsRequest
 */
export const ListTicketBuyerTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTicketBuyerTableRowsRequest",
  () => [
    { no: 1, name: "ticketing_filter", kind: "message", T: AnalyticsTicketingFilter },
    { no: 2, name: "donation_time_range", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTicketBuyerTableRowsResponse
 */
export const ListTicketBuyerTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTicketBuyerTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: TicketBuyerTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SoftCreditTableRow
 */
export const SoftCreditTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SoftCreditTableRow",
  () => [
    { no: 1, name: "soft_credit_at", kind: "message", T: Timestamp },
    { no: 2, name: "donation_at", kind: "message", T: Timestamp },
    { no: 3, name: "soft_credit_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "donation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "recipient_consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "donor_consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "recipient_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "recipient_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "donor_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "donor_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "recipient_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "recipient_address", kind: "message", T: Address },
    { no: 13, name: "soft_credit_amount", kind: "message", T: Money },
    { no: 14, name: "net_donation_amount", kind: "message", T: Money },
    { no: 15, name: "campaign_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "fund_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "appeal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "acknowledgment_status", kind: "enum", T: proto3.getEnumType(AcknowledgmentStatus) },
    { no: 19, name: "recipient_household_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "recipient_formal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "recipient_informal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "recipient_recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "recipient_formal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "recipient_informal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSoftCreditTableRowsRequest
 */
export const ListSoftCreditTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSoftCreditTableRowsRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSoftCreditTableRowsResponse
 */
export const ListSoftCreditTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSoftCreditTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: SoftCreditTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DonationsByDonorTableRow
 */
export const DonationsByDonorTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DonationsByDonorTableRow",
  () => [
    { no: 1, name: "constituent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "household_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "donor_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "contributed_revenue_net_amount", kind: "message", T: Money },
    { no: 5, name: "soft_credit_net_amount", kind: "message", T: Money },
    { no: 6, name: "all_donations_net_amount", kind: "message", T: Money },
    { no: 7, name: "address", kind: "message", T: Address },
    { no: 8, name: "donor_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "donor_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "donor_formal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "donor_informal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "donor_recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "donor_formal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "donor_informal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "donor_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "donor_phone_number", kind: "message", T: PhoneNumber },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListDonationsByDonorTableRowsRequest
 */
export const ListDonationsByDonorTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListDonationsByDonorTableRowsRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "donation_type_filters", kind: "enum", T: proto3.getEnumType(AnalyticsDonationTypeFilter), repeated: true },
    { no: 3, name: "campaign_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "fund_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListDonationsByDonorTableRowsResponse
 */
export const ListDonationsByDonorTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListDonationsByDonorTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: DonationsByDonorTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AnalyticsSeasonPackageFilter
 */
export const AnalyticsSeasonPackageFilter = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AnalyticsSeasonPackageFilter",
  () => [
    { no: 1, name: "season_package_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * Season Package Metrics
 *
 * @generated from message cuebox.services.staff.v1.SeasonPackageMetrics
 */
export const SeasonPackageMetrics = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeasonPackageMetrics",
  () => [
    { no: 1, name: "season_packages_sold_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "net_sales_amount", kind: "message", T: Money },
    { no: 3, name: "tickets_sold_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "season_package_buyers_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "first_time_season_package_buyers_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "renewed_season_package_buyers_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSeasonPackageMetricsRequest
 */
export const GetSeasonPackageMetricsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSeasonPackageMetricsRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "season_package_filter", kind: "message", T: AnalyticsSeasonPackageFilter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSeasonPackageMetricsResponse
 */
export const GetSeasonPackageMetricsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSeasonPackageMetricsResponse",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "metrics", kind: "message", T: SeasonPackageMetrics },
  ],
);

/**
 * Cumulative Season Package Sold Over Time Chart
 *
 * @generated from message cuebox.services.staff.v1.SeasonPackageSalesDataPoint
 */
export const SeasonPackageSalesDataPoint = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeasonPackageSalesDataPoint",
  () => [
    { no: 1, name: "season_package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "season_package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cumulative_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SeasonPackageSalesDataRow
 */
export const SeasonPackageSalesDataRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeasonPackageSalesDataRow",
  () => [
    { no: 1, name: "time", kind: "message", T: Timestamp },
    { no: 2, name: "data_points", kind: "message", T: SeasonPackageSalesDataPoint, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SeasonPackageSalesTable
 */
export const SeasonPackageSalesTable = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeasonPackageSalesTable",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "rows", kind: "message", T: SeasonPackageSalesDataRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSeasonPackageSalesChartRequest
 */
export const GetSeasonPackageSalesChartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSeasonPackageSalesChartRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "season_package_filter", kind: "message", T: AnalyticsSeasonPackageFilter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSeasonPackageSalesChartResponse
 */
export const GetSeasonPackageSalesChartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSeasonPackageSalesChartResponse",
  () => [
    { no: 1, name: "table", kind: "message", T: SeasonPackageSalesTable },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TopShowsBySeasonPackageRow
 */
export const TopShowsBySeasonPackageRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TopShowsBySeasonPackageRow",
  () => [
    { no: 1, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "show_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "tickets_sold_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TopShowsBySeasonPackageTable
 */
export const TopShowsBySeasonPackageTable = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TopShowsBySeasonPackageTable",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "rows", kind: "message", T: TopShowsBySeasonPackageRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetTopShowsBySeasonPackageChartRequest
 */
export const GetTopShowsBySeasonPackageChartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetTopShowsBySeasonPackageChartRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "season_package_filter", kind: "message", T: AnalyticsSeasonPackageFilter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetTopShowsBySeasonPackageChartResponse
 */
export const GetTopShowsBySeasonPackageChartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetTopShowsBySeasonPackageChartResponse",
  () => [
    { no: 1, name: "table", kind: "message", T: TopShowsBySeasonPackageTable },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SeasonPackageTicketTypeRow
 */
export const SeasonPackageTicketTypeRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeasonPackageTicketTypeRow",
  () => [
    { no: 1, name: "ticket_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_type_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "tickets_sold_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SeasonPackageTicketTypeTable
 */
export const SeasonPackageTicketTypeTable = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeasonPackageTicketTypeTable",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "rows", kind: "message", T: SeasonPackageTicketTypeRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSeasonPackageTicketTypeChartRequest
 */
export const GetSeasonPackageTicketTypeChartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSeasonPackageTicketTypeChartRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "season_package_filter", kind: "message", T: AnalyticsSeasonPackageFilter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSeasonPackageTicketTypeChartResponse
 */
export const GetSeasonPackageTicketTypeChartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSeasonPackageTicketTypeChartResponse",
  () => [
    { no: 1, name: "table", kind: "message", T: SeasonPackageTicketTypeTable },
  ],
);

/**
 * List of Season Packages Report
 *
 * @generated from message cuebox.services.staff.v1.SeasonPackageTableRow
 */
export const SeasonPackageTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeasonPackageTableRow",
  () => [
    { no: 1, name: "household_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "order_ats", kind: "message", T: Timestamp, repeated: true },
    { no: 5, name: "household_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "consumer_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "consumer_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "consumer_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "consumer_secondary_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "consumer_phone_number", kind: "message", T: PhoneNumber },
    { no: 11, name: "consumer_address", kind: "message", T: Address },
    { no: 12, name: "billing_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "billing_address", kind: "message", T: Address },
    { no: 14, name: "shipping_address", kind: "message", T: Address },
    { no: 15, name: "season_package_item_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "season_package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "season_package_item_net_amount", kind: "message", T: Money },
    { no: 18, name: "season_package_item_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 19, name: "tickets_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 20, name: "show_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 21, name: "ticket_type_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 22, name: "price_level_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 23, name: "seat_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 24, name: "original_seat_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 25, name: "order_notes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 26, name: "order_net_donation_amounts", kind: "message", T: Money, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSeasonPackageTableRowsRequest
 */
export const ListSeasonPackageTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSeasonPackageTableRowsRequest",
  () => [
    { no: 1, name: "season_package_filter", kind: "message", T: AnalyticsSeasonPackageFilter },
    { no: 2, name: "report_type", kind: "enum", T: proto3.getEnumType(ListSeasonPackageReportType) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSeasonPackageTableRowsResponse
 */
export const ListSeasonPackageTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSeasonPackageTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: SeasonPackageTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AnalyticsMembershipSubscriptionFilter
 */
export const AnalyticsMembershipSubscriptionFilter = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AnalyticsMembershipSubscriptionFilter",
  () => [
    { no: 1, name: "membership_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * List of Membership Subscription Report
 *
 * @generated from message cuebox.services.staff.v1.MembershipSubscriptionTableRow
 */
export const MembershipSubscriptionTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MembershipSubscriptionTableRow",
  () => [
    { no: 1, name: "membership_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "membership_subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "household_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "consumer_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "consumer_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "consumer_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "consumer_address", kind: "message", T: Address },
    { no: 10, name: "consumer_phone_number", kind: "message", T: PhoneNumber },
    { no: 11, name: "membership_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "status", kind: "enum", T: proto3.getEnumType(SubscriptionStatus) },
    { no: 13, name: "started_at", kind: "message", T: Timestamp },
    { no: 14, name: "canceled_at", kind: "message", T: Timestamp },
    { no: 15, name: "recurring_amount", kind: "message", T: Money },
    { no: 16, name: "frequency", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency) },
    { no: 17, name: "next_payment_at", kind: "message", T: Timestamp },
    { no: 18, name: "lifetime_paid_amount", kind: "message", T: Money },
    { no: 19, name: "current_period_applied_ticket_benefit_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 20, name: "current_period_applied_discount_benefit_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 21, name: "consumer_formal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "consumer_informal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "consumer_recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "consumer_formal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "consumer_informal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "consumer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMembershipSubscriptionTableRowsRequest
 */
export const ListMembershipSubscriptionTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMembershipSubscriptionTableRowsRequest",
  () => [
    { no: 1, name: "membership_subscription_filter", kind: "message", T: AnalyticsMembershipSubscriptionFilter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMembershipSubscriptionTableRowsResponse
 */
export const ListMembershipSubscriptionTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMembershipSubscriptionTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: MembershipSubscriptionTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.PayoutTableRow
 */
export const PayoutTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.PayoutTableRow",
  () => [
    { no: 1, name: "paid_at", kind: "message", T: Timestamp },
    { no: 2, name: "gross_amount", kind: "message", T: Money },
    { no: 3, name: "adjustment_amount", kind: "message", T: Money },
    { no: 4, name: "payment_processor_fee_amount", kind: "message", T: Money },
    { no: 5, name: "payout_amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListPayoutTableRowsRequest
 */
export const ListPayoutTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListPayoutTableRowsRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListPayoutTableRowsResponse
 */
export const ListPayoutTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListPayoutTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: PayoutTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MembershipMetrics
 */
export const MembershipMetrics = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MembershipMetrics",
  () => [
    { no: 1, name: "active_memberships_count", kind: "message", T: ActiveMembershipsCount },
    { no: 2, name: "membership_sales", kind: "message", T: MembershipSales },
    { no: 3, name: "retention_rate", kind: "message", T: MembershipRetentionRate },
    { no: 4, name: "new_memberships_count", kind: "message", T: NewMembershipsCount },
    { no: 5, name: "canceled_memberships_count", kind: "message", T: CanceledMembershipsCount },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ActiveMembershipsCount
 */
export const ActiveMembershipsCount = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ActiveMembershipsCount",
  () => [
    { no: 1, name: "current_period_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "previous_period_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "percentage_delta_from_previous_period", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MembershipSales
 */
export const MembershipSales = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MembershipSales",
  () => [
    { no: 1, name: "current_period_net_amount", kind: "message", T: Money },
    { no: 2, name: "previous_period_net_amount", kind: "message", T: Money },
    { no: 3, name: "percentage_delta_from_previous_period", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MembershipRetentionRate
 */
export const MembershipRetentionRate = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MembershipRetentionRate",
  () => [
    { no: 1, name: "current_period_retention_rate", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "previous_period_retention_rate", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "percentage_delta_from_previous_period", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.NewMembershipsCount
 */
export const NewMembershipsCount = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.NewMembershipsCount",
  () => [
    { no: 1, name: "current_period_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "previous_period_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "percentage_delta_from_previous_period", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CanceledMembershipsCount
 */
export const CanceledMembershipsCount = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CanceledMembershipsCount",
  () => [
    { no: 1, name: "current_period_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "previous_period_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "percentage_delta_from_previous_period", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetMembershipMetricsRequest
 */
export const GetMembershipMetricsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetMembershipMetricsRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "membership_subscription_filter", kind: "message", T: AnalyticsMembershipSubscriptionFilter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetMembershipMetricsResponse
 */
export const GetMembershipMetricsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetMembershipMetricsResponse",
  () => [
    { no: 1, name: "current_period_time_range", kind: "message", T: TimeRange },
    { no: 2, name: "previous_period_time_range", kind: "message", T: TimeRange },
    { no: 3, name: "metrics", kind: "message", T: MembershipMetrics },
  ],
);

/**
 * Number of Active Members Chart
 *
 * @generated from message cuebox.services.staff.v1.GetActiveMembershipsChartRequest
 */
export const GetActiveMembershipsChartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetActiveMembershipsChartRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "membership_subscription_filter", kind: "message", T: AnalyticsMembershipSubscriptionFilter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetActiveMembershipsChartResponse
 */
export const GetActiveMembershipsChartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetActiveMembershipsChartResponse",
  () => [
    { no: 1, name: "table", kind: "message", T: ActiveMembershipsTable },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ActiveMembershipsTable
 */
export const ActiveMembershipsTable = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ActiveMembershipsTable",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "rows", kind: "message", T: ActiveMembershipsRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ActiveMembershipsRow
 */
export const ActiveMembershipsRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ActiveMembershipsRow",
  () => [
    { no: 1, name: "time", kind: "message", T: Timestamp },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * Cumulative Membership Sales Over Time Chart
 *
 * @generated from message cuebox.services.staff.v1.GetMembershipSalesChartRequest
 */
export const GetMembershipSalesChartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetMembershipSalesChartRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "membership_subscription_filter", kind: "message", T: AnalyticsMembershipSubscriptionFilter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetMembershipSalesChartResponse
 */
export const GetMembershipSalesChartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetMembershipSalesChartResponse",
  () => [
    { no: 1, name: "current_period_table", kind: "message", T: MembershipSalesTable },
    { no: 2, name: "previous_period_table", kind: "message", T: MembershipSalesTable },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MembershipSalesTable
 */
export const MembershipSalesTable = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MembershipSalesTable",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "rows", kind: "message", T: MembershipSalesRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MembershipSalesRow
 */
export const MembershipSalesRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MembershipSalesRow",
  () => [
    { no: 1, name: "time", kind: "message", T: Timestamp },
    { no: 2, name: "net_amount", kind: "message", T: Money },
  ],
);

/**
 * Sales Summary Report
 *
 * @generated from message cuebox.services.staff.v1.SalesSummaryTableRow
 */
export const SalesSummaryTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SalesSummaryTableRow",
  () => [
    { no: 1, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sold_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "item_price_amount", kind: "message", T: Money },
    { no: 4, name: "fee_amount", kind: "message", T: Money },
    { no: 5, name: "net_amount", kind: "message", T: Money },
    { no: 6, name: "adjustment_amount", kind: "message", T: Money },
    { no: 7, name: "credit_card_fee_amount", kind: "message", T: Money },
    { no: 8, name: "payout_amount", kind: "message", T: Money },
    { no: 9, name: "general_ledger_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSalesSummaryTableRowsRequest
 */
export const ListSalesSummaryTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSalesSummaryTableRowsRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
    { no: 2, name: "date_type_filter", kind: "enum", T: proto3.getEnumType(AnalyticsDateTypeFilter) },
    { no: 3, name: "payment_type_filters", kind: "enum", T: proto3.getEnumType(AnalyticsPaymentTypeFilter), repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSalesSummaryTableRowsResponse
 */
export const ListSalesSummaryTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSalesSummaryTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: SalesSummaryTableRow, repeated: true },
  ],
);

/**
 * Ticket Sales By Ticket Type Report
 *
 * @generated from message cuebox.services.staff.v1.TicketSalesByTicketTypeTableRow
 */
export const TicketSalesByTicketTypeTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketSalesByTicketTypeTableRow",
  () => [
    { no: 1, name: "show_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "showtime_starts_at", kind: "message", T: Timestamp },
    { no: 3, name: "ticket_type_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sold_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "net_amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTicketSalesByTicketTypeTableRowsRequest
 */
export const ListTicketSalesByTicketTypeTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTicketSalesByTicketTypeTableRowsRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTicketSalesByTicketTypeTableRowsResponse
 */
export const ListTicketSalesByTicketTypeTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTicketSalesByTicketTypeTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: TicketSalesByTicketTypeTableRow, repeated: true },
  ],
);

/**
 * Sales By Discount Report
 *
 * @generated from message cuebox.services.staff.v1.SalesByDiscountTableRow
 */
export const SalesByDiscountTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SalesByDiscountTableRow",
  () => [
    { no: 1, name: "product_type", kind: "enum", T: proto3.getEnumType(ProductType) },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "discount_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sold_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "net_amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSalesByDiscountTableRowsRequest
 */
export const ListSalesByDiscountTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSalesByDiscountTableRowsRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSalesByDiscountTableRowsResponse
 */
export const ListSalesByDiscountTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSalesByDiscountTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: SalesByDiscountTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TicketHoldTableRow
 */
export const TicketHoldTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketHoldTableRow",
  () => [
    { no: 1, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "showtime_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "show_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "showtime_starts_at", kind: "message", T: Timestamp },
    { no: 5, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "consumer_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "consumer_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "consumer_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "consumer_phone_number", kind: "message", T: PhoneNumber },
    { no: 10, name: "consumer_address", kind: "message", T: Address },
    { no: 11, name: "seat_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTicketHoldTableRowsRequest
 */
export const ListTicketHoldTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTicketHoldTableRowsRequest",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTicketHoldTableRowsResponse
 */
export const ListTicketHoldTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTicketHoldTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: TicketHoldTableRow, repeated: true },
  ],
);

/**
 * Form Question Response Report
 *
 * @generated from message cuebox.services.staff.v1.FormQuestionResponseTableRow
 */
export const FormQuestionResponseTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FormQuestionResponseTableRow",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "constituent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "response_submitted_at", kind: "message", T: Timestamp },
    { no: 5, name: "order_created_at", kind: "message", T: Timestamp },
    { no: 6, name: "customer_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "customer_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "customer_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "billing_phone_number", kind: "message", T: PhoneNumber },
    { no: 10, name: "billing_address", kind: "message", T: Address },
    { no: 11, name: "item_product_type", kind: "enum", T: proto3.getEnumType(ProductType) },
    { no: 12, name: "item_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "item_detail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "order_item_payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 15, name: "question_responses", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: FormQuestionResponse} },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.FormQuestionResponseTableFilters
 */
export const FormQuestionResponseTableFilters = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FormQuestionResponseTableFilters",
  () => [
    { no: 1, name: "product_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListFormQuestionResponseTableRowsRequest
 */
export const ListFormQuestionResponseTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListFormQuestionResponseTableRowsRequest",
  () => [
    { no: 1, name: "filters", kind: "message", T: FormQuestionResponseTableFilters },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.QuestionColumnHeader
 */
export const QuestionColumnHeader = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.QuestionColumnHeader",
  () => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListFormQuestionResponseTableRowsResponse
 */
export const ListFormQuestionResponseTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListFormQuestionResponseTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: FormQuestionResponseTableRow, repeated: true },
    { no: 2, name: "column_headers", kind: "message", T: QuestionColumnHeader, repeated: true },
  ],
);

/**
 * Form Question and Answer Report
 *
 * @generated from message cuebox.services.staff.v1.FormQuestionAndAnswerTableRow
 */
export const FormQuestionAndAnswerTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FormQuestionAndAnswerTableRow",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "constituent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "order_created_at", kind: "message", T: Timestamp },
    { no: 5, name: "customer_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "customer_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "customer_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "billing_phone_number", kind: "message", T: PhoneNumber },
    { no: 9, name: "billing_address", kind: "message", T: Address },
    { no: 10, name: "item_product_type", kind: "enum", T: proto3.getEnumType(ProductType) },
    { no: 11, name: "item_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "item_detail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "order_item_payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 14, name: "order_payment_status", kind: "enum", T: proto3.getEnumType(OrderPaymentStatus) },
    { no: 15, name: "question_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "response_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "response_scope", kind: "enum", T: proto3.getEnumType(FormQuestionScope) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.FormQuestionAndAnswerTableFilters
 */
export const FormQuestionAndAnswerTableFilters = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FormQuestionAndAnswerTableFilters",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListFormQuestionAndAnswerTableRowsRequest
 */
export const ListFormQuestionAndAnswerTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListFormQuestionAndAnswerTableRowsRequest",
  () => [
    { no: 1, name: "filters", kind: "message", T: FormQuestionAndAnswerTableFilters },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListFormQuestionAndAnswerTableRowsResponse
 */
export const ListFormQuestionAndAnswerTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListFormQuestionAndAnswerTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: FormQuestionAndAnswerTableRow, repeated: true },
  ],
);

